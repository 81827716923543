<template>
  <div>
    <div class="load-com tc">
      <div class="text" @click="backTop">点我返回顶部</div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    backTop() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
};
</script>
<style lang="less" scoped>
.load-com {
  padding: .3rem .2rem ;
  font-size: 0.28rem;
  .text{
    padding: 0.3rem 0;
    background: #fff;
    border-radius: .1rem;
  }
}
</style>
